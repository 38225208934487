// Polyfills

// import 'ie-shim'; // Internet Explorer 9 support

import 'core-js/es6';
import 'core-js/es7';
import 'zone.js/dist/zone';

// Typescript emit helpers polyfill
// import 'ts-helpers';

if ('production' === ENV) {
  // Production


} else {
  // Development

  Error.stackTraceLimit = Infinity;
  require('reflect-metadata');
  require('zone.js/dist/long-stack-trace-zone');

}
